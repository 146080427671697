@use 'variables'as theme;
@use './everything'as *;
@include react-md-utils;

.rmd-button {
  font-family: theme.$font-demi;

  &.cta {
    height: 3.5rem;
  }

  &.full {
    width: 100%;
  }

  &.primary {
    font-size: 1rem;

    &[disabled] {
      background-color: theme.$primary-color;
      color: theme.$white;
      opacity: .5;
    }
  }

  &.secondary {
    color: theme.$white;
    font-size: 1rem;

    &[disabled] {
      background-color: theme.$secondary-color;
      color: theme.$white;
      opacity: .5;
    }
  }

  &.tertiary {
    background-color: #f4f4f4;
    color: #000;
  }
}

.rmd-label {
  color: theme.$secondary-text-color;
}

.rmd-button--disabled {
  background-color: rgba(0, 0, 0, .12);
}

.rmd-button--primary {
  @include rmd-theme-update-var(on-primary, theme.$white);
}

.ph-checkbox {
  @include rmd-button-theme-update-var(icon-size, 1.5rem);
  @include rmd-icon-theme-update-var(color, theme.$primary-color);
  @include rmd-theme-update-var(secondary, theme.$primary-color);
  @include rmd-theme-update-var(text-secondary-on-background, theme.$primary-color);
  height: 2rem;

  label {
    color: theme.$secondary-text-color;
    cursor: pointer;
    flex-grow: 1;
    font-size: .875rem;
  }

  &.top-control {
    label {
      font-family: theme.$font-demi;
    }
  }

  &.secondary {
    @include rmd-icon-theme-update-var(color, theme.$secondary-color);
    @include rmd-theme-update-var(secondary, theme.$secondary-color);
    @include rmd-theme-update-var(text-secondary-on-background, #646464);
  }
}

.rmd-toggle-container {
  --rmd-form-active-color: #fff;

  .rmd-label {
    cursor: pointer;
  }

  .rmd-switch__ball::after {
    background-color: #fff;
  }

  &.active {
    .rmd-switch {
      background-color: theme.$primary-color;
    }
  }
}

.rmd-toggle__input:checked + .rmd-toggle__icon {
  color: theme.$primary-color;
}

.rmd-toast__action button {
  color: theme.$secondary-color;
  font-family: theme.$font-demi;
}

.rmd-switch__input:checked + .rmd-switch__ball {
  transform: translateX(1.25rem);
}

.rmd-dialog {
  border-radius: $border-radius;
}

.rmd-option--selected {
  background-color: theme.$primary-color;
  color: #fff;
}

.read-only-switch {
  opacity: .5;

  .rmd-switch__input:checked:disabled + .rmd-switch__ball::after {
    background-color: #fff;
  }

  .rmd-switch__input:disabled + .rmd-switch__ball::after {
    background-color: #fff;
  }
}

.ph-radio {
  .rmd-toggle {
    --rmd-button-icon-size: 1rem;
  }

  .rmd-label {
    margin-left: .5rem;
  }
}
