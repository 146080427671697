@use '../../scss/variables' as *;

.create-event-dialog {
  --rmd-icon-color: #{$primary-color};

  overflow-y: auto;
  position: relative;
  width: 43.75rem;

  .create-event-dialog-content {
    padding: 1.5rem;
    width: 100%;
  }

  .close-button {
    --rmd-icon-color: #{$secondary-text-color};
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .create-event-dialog-actions {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem;
    width: 100%;
  }

  .event-form {
    display: grid;
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .event-name-container {
    align-items: baseline;
    display: flex;
    width: calc(100% - 3rem);

    .event-name-input {
      flex: 1;
      margin-right: 1rem;
    }

    .event-name-date {
      color: $disabled-color;
      cursor: info;
      flex: 0;
      font-family: $font-demi;
      font-size: 1.25rem;
      white-space: nowrap;
    }

    .rmd-text-field-container {
      --rmd-form-text-height: 3.5rem;
    }

    .rmd-text-field-container--underline-left-addon {
      --rmd-form-label-left-offset: 0;
    }

    .rmd-text-field {
      color: $secondary-text-color;
      font-size: 2rem;

      &::placeholder {
        color: #dadada;
      }
    }
  }

  .form-fieldset {
    border-color: #dadada;
    border-radius: .375rem;
    display: grid;
    grid-row-gap: 1rem;
    padding: .75rem;
  }

  .form-field-container {
    align-content: space-around;
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1.5rem 1fr 1fr;
    justify-content: space-between;
    width: 100%;

    &.date-time-form-field-container {
      grid-template-columns: 1.5rem 1fr;

      .form-field-label {
        margin-bottom: .5rem;
      }

      .form-field-input {
        display: block;
      }
    }

    &.no-icon {
      grid-template-columns: 1fr 1fr;
    }

    .form-field-label-description {
      grid-column: 2 / span 2;
      padding-right: 3.25rem;
    }
  }

  .form-field-icon {
    align-items: center;
    display: inline-flex;

    &.time-range-icon {
      align-items: start;
    }
  }

  .form-field-label {
    align-items: center;
    color: $secondary-text-color;
    display: flex;
    font-family: $font-demi;

    .form-field-label-hint {
      color: rgba($secondary-text-color, .4);
      cursor: pointer;
      font-family: $font-regular;
      margin-left: 1rem;
      text-decoration: underline;
    }
  }

  .form-field-input {
    display: flex;
    justify-content: flex-end;
  }

  .time-label {
    color: #7a7a7a;
  }

  .form-field-dropdown {
    width: 100%;
  }

  .separator {
    margin: 0;
  }
}
