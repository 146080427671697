@use '../../scss/variables' as *;

.recurring-event-form {
  font-size: .75rem;

  .form-section {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  .form-section-child {
    margin-right: .5rem;
  }

  .form-section-label {
    margin-right: .75rem;
  }

  .recurring-input {
    background-color: $input-bg-color;
    border-radius: 3px;
    color: $secondary-text-color;
    font-size: .875rem;
    height: 2.375rem;
    max-width: 3.75rem;
    padding: .375rem;

    &[disabled] {
      background-color: $disabled-input-bg-color;
    }
  }

  .button-group {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button-child {
      align-items: center;
      background: $input-bg-color;
      border-radius: 50%;
      cursor: pointer;
      display: inline-flex;
      height: 1.5rem;
      justify-content: center;
      margin: 0 .25rem;
      width: 1.5rem;

      &.active {
        background: $primary-color;
        color: #fff;
      }
    }
  }

  .end-section {
    &.form-section {
      align-items: flex-start;
    }

    .form-section-label {
      padding-top: .25rem;
    }

    .ph-radio + .ph-radio {
      margin-top: 1.5rem;
    }
  }

  .farthest-date {
    color: #757575;
    font-family: $font-demi;
    margin-left: .25rem;
  }

  .after-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 9.375rem;
  }
}
