.recurring-preview-dialog {
  --rmd-dialog-header-padding-bottom: 1rem;
  --rmd-dialog-header-padding: 1rem;

  .recurring-preview-dialog-content {
    min-width: 28.625rem;
    padding: 0 1.5rem;
    width: 100%;
  }

  .recurring-event-list {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 1fr;
    list-style-type: none;
    margin: 0;
    max-height: 22.125rem;
    overflow-y: auto;
    padding: 0;
  }

  .recurring-preview-dialog-actions {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    width: 100%;
  }
}
