@use '../../scss/variables' as *;
@use '~@parkhub/parkhub-ui/dist/styles/media-queries';
$filter-group-border-radius: .5rem;

.filter-group {
  font-size: .875rem;
}

.filter-group-button {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 1rem;
  color: $secondary-text-color;
  cursor: pointer;
  display: flex;
  font-family: $font-demi;
  gap: .25rem;
  height: 2rem;
  justify-content: space-between;
  padding: 0 .5rem 0 1rem;

  &.filter-group-button-active {
    background-color: $primary-color;
    color: #fff;

    .ph-font-icon {
      color: $secondary-text-color;
    }
  }
}

.filter-group-button-label-container {
  display: flex;
  gap: .25rem;
  line-height: 2rem;
  text-transform: capitalize;
  white-space: nowrap;

  .filter-text {
    display: inline-flex;
  }

  .filter-count {
    display: inline-flex;
    justify-content: center;
    min-width: 1.75rem;
  }
}

.filter-group-button-action {
  align-items: center;
  display: inline-flex;
  height: 1.5rem;
  width: 1.5rem;
}

.filter-group-popover {
  background: #fff;
  border-radius: $filter-group-border-radius;
  box-shadow: 0 .25rem 1rem rgba(#828da8, .2);
  z-index: 1;
}

%header {
  align-items: center;
  border-radius: $filter-group-border-radius $filter-group-border-radius 0 0;
  display: flex;
  font-family: $font-demi;
  padding: .5rem .75rem;
}

.filter-group-menu-header {
  @extend %header;
  background-color: $primary-color;
  color: #fff;
  justify-content: space-between;
}

.filter-group-item-header {
  @extend %header;
  background-color: #fff;
  color: $secondary-text-color;
  gap: .25rem;
}

.filter-group-content {
  min-width: 16.25rem;
}

.filter-group-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-group-list-item {
  background: #fff;
  padding: 0 .75rem;
  transition: background .2s ease-in-out;

  &:hover {
    background: #f7f7f7;
  }
}

.filter-group-list-item-disabled {
  opacity: .5;
  pointer-events: none;
}

.filter-group-list-item-content {
  align-items: center;
  border-bottom: .125rem solid #f7f7f7;
  cursor: pointer;
  display: flex;
  padding: .625rem 0;

  .check-icon {
    color: $primary-color;
  }

  .navigate-icon {
    color: #b2b2b2;
  }
}

.filter-group-list-item-label,
.filter-group-list-item-icon-left,
.filter-group-list-item-icon-right {
  height: 1.5rem;
  line-height: 1.5rem;
}

.filter-group-list-item-label {
  flex-grow: 1;
  font-family: $font-demi;
  margin: 0 .25rem;
}

.filter-group-action-container {
  padding: .75rem;
}
