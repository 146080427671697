.save-choice-modal {
  --rmd-dialog-header-padding-bottom: 1rem;
  --rmd-dialog-header-padding: 1.5rem;

  .dialog-content {
    min-width: 28.625rem;
    padding: 0 1.5rem;
    width: 100%;
  }

  .dialog-actions {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    width: 100%;
  }
}
