@use '../../scss/variables' as *;
@use 'sass:color';

.filter-selection {
  padding: 0 .75rem;

  .filter-selection-list-container {
    max-height: 12.5rem;
    overflow-y: auto;
  }

  .filter-selection-list {
    background-color: #fff;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
    }
  }

  .filter-selection-search {
    margin-bottom: 8px;
    position: relative;

    input {
      background: #f7f7f7;
      border: 0;
      border-radius: .25rem;
      font-family: $font-regular;
      font-size: .875rem;
      height: 1.875rem;
      line-height: 1.875rem;
      min-width: 100%;
      padding-left: .5rem;
      padding-right: 1.75rem;
    }

    span {
      height: 1.5rem;
      position: absolute;
      right: .25rem;
      top: .375rem;
      width: 1.5rem;
    }
  }

  .filter-selection-action-container {
    align-items: center;
    background-color: #fff;
    bottom: 0;
    display: flex;
    height: 3.75rem;
    justify-content: flex-end;
    position: sticky;
    z-index: 10;
  }

  .filter-selection-apply-button {
    background-color: $primary-color;
    color: $white;
    width: 100%;
  }

  .filter-selection-apply-button:disabled {
    background-color: $disabled-color;
    color: color.adjust($disabled-color, $lightness: 20%);
  }
}
